const _ = require('../../../lodash');
const BaseEntry = require('./BaseEntry');
const constants = require('../../../constants');
const DomainException = require('../../DomainException');
const { answerStates } = constants;
const { countDecimals } = require('../../../utils/notools');

class PhotoEntry extends BaseEntry {
   constructor(aggregateRoot, componentData, items, entries, ruleEvaluator) {
      super(aggregateRoot, componentData, items, entries, ruleEvaluator);
   }

   update({ actionedOn, images, geoLat, geoLong, geoTimeStamp }) {
      const item = this.item;

      const newItemState = item.state | answerStates.ANSWERED;

      let responses = [];

      _.each(images, (so, index) => {
         const r = { no: index + 1, upd: so.upd ? so.upd : actionedOn, val: { local: so.local, remote: so.remote } };
         // If there is an id provided this will be the id we use to
         // link to the mediaassets table once the image has been uploaded to the cloud
         // a job will then restamp the correct url from the mediaasset over the remote url and clear local
         if (so.id) {
            r.id = so.id;
         }

         responses.push(r);
      });

      const p = {
         idItem: this.idItem,
         state: newItemState,
         actionedOn,
         responses,
         noSubmissionItem: item.no,
         noSubmission: this._aggregateRoot.getSubmissionNo(),
         geoLat,
         geoLong,
         geoTimeStamp
      };

      this.apply('PHOTO_FIELD_UPDATED', p);

      item.response = responses;
      item.state = newItemState;
      item.geoLat = geoLat;
      item.geoLong = geoLong;
      item.geoTimeStamp = geoTimeStamp;
   }

   tryParsePhoto(input) {
      let result = {};
      result.success = false;
      result.value = input;

      let parsedValue = null;

      try {
         const currentImage = input[0];

         const url = currentImage.val.local || currentImage.val.local;
         if (url == undefined) {
            throw new DomainException('Invalid image url');
         }

         // try to parse the input as a BigInt value
         parsedValue = input;
         result.success = true;
      } catch {
         result.success = false;
      }

      if (result.success) {
         result.value = parsedValue;
      }

      return result;
   }

   validateEntry() {
      const item = this.item;
      const { state } = item;
      let isInvalid = false;

      let isSkipped = (state & answerStates.SKIPPED) == answerStates.SKIPPED;

      if (
         (!_.isArray(item.response) ||
            (_.isArray(item.response) && item.response.length == 0) ||
            !this.tryParsePhoto(item.response).success) &&
         !isSkipped
      ) {
         isInvalid = true;
      }

      return isInvalid;
   }

   lock() {
      const item = this.item;
      const { state } = item;
      item.state = state | answerStates.LOCKED;
   }

   unlock() {
      const item = this.item;
      const { state } = item;
      item.state = state & ~answerStates.LOCKED;
   }

   validate() {
      const item = this.item;
      const { state } = item;

      const isInvalid = this.validateEntry();

      if (isInvalid) {
         item.state = state | answerStates.INVALID;
      } else {
         item.state = state & ~answerStates.INVALID;
      }

      return !isInvalid;
   }

   getFriendlyValue() {
      const item = this.item;
      // TODO
      // How do we summarise photos?
      console.log('PHOTO', item);
      return '';
   }
}

module.exports = PhotoEntry;
