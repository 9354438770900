import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import React, { Component, Fragment } from 'react';
import { lodash as _, constants } from '../../../common';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import SweetAlert from '../../../components/feedback/SweetAlert';
import FieldDataSourceEditor from '../../../components/forms/FieldPropertyEditor/components/FieldDataSourceEditor';
import DataSourceRulesEditor from '../../../components/forms/FieldPropertyEditor/components/DataSourceRulesEditor';
import RepeatTimesSelector from '../../../components/forms/FieldPropertyEditor/components/RepeatTimesSelector';
import {
   CopyIcon,
   DateFormItemIcon,
   DeleteIcon,
   NumberFormItemIcon,
   OptionsFormItemIcon,
   PhotoFormItemIcon,
   RepeaterFormItemIcon,
   SectionFormItemIcon,
   TextFormItemIcon,
   ToDoFormItemIcon
} from '../../../components/icons';
import FileResourceList from '../../../components/resources/FileResourceList';

const {
   fieldRequiredTypes,
   formItemTypes,
   optionsFormItemDisplayTypes,
   repeaterFormItemTypes,
   datetimeFormItemTypes,
   repeaterCadenceTypes
} = constants;

const decimalPlacesList = [{ id: 0, name: 'None' }, { id: 1, name: '1' }, { id: 2, name: '2' }, , { id: 3, name: '3' }];
const blankOptionsBinding = { noDataSource: null, idField: 'id', txtField: 'txt' };

const tabStates = {
   PROPS: 1,
   GUIDANCE: 2,
   LOGIC: 3
};

class FieldPropertyEditor extends Component {
   constructor(props) {
      super(props);

      this.captionRef = React.createRef();

      this.state = this.buildFromProps(props);

      this.stateRenders = {};
      this.stateRenders[tabStates.PROPS] = this.renderProps.bind(this);
      this.stateRenders[tabStates.GUIDANCE] = this.renderGuidance.bind(this);
      this.stateRenders[tabStates.LOGIC] = this.renderLogic.bind(this);
   }

   buildFromProps(props, s = {}) {
      let selectedItem = props.selectedItem ? { ...props.selectedItem } : null;

      return _.merge(
         {
            currentItem: selectedItem,
            broadcast: false,
            valid: false,
            shouldValidate: true,
            validations: {},
            highlight: false,
            editingOptions: false,
            editingList: false,
            isDeleting: false,
            tabState: tabStates.PROPS
         },
         s
      );
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const currentItem = this.state.currentItem;
      const nextItem = nextProps.selectedItem;

      if (!nextItem || !_.isEqual(currentItem, nextItem)) {
         this.setState(this.buildFromProps(nextProps, { highlight: true }));
      }
   }

   componentDidUpdate() {
      const { shouldValidate, currentItem, broadcast, highlight } = this.state;
      if (shouldValidate) {
         var o = this.validate(currentItem);
         this.setState({ ...o, shouldValidate: false });
      }

      if (highlight) {
         if (this.captionRef.current) {
            if (this.captionRef.current.value && this.captionRef.current.value.length > 0) {
               this.captionRef.current.select();
            }
            this.captionRef.current.focus();
         }
         this.setState({ highlight: false });
      }

      if (broadcast) {
         const onChange = this.props.onChange;
         if (onChange) {
            onChange({ item: currentItem });
         }

         this.setState({ broadcast: false });
      }
   }

   _onFieldLabelChange = (e) => {
      const { currentItem } = this.state;

      currentItem['label'] = e.target.value;

      this.setState({ currentItem, broadcast: true });
   };

   _onFieldLabelPaste = (event) => {
      // Prevent the default paste behavior to capture the pasted text
      event.preventDefault();
      const onCopy = this.props.onCopy;

      // Get the pasted text from the clipboard
      const pastedText = event.clipboardData.getData('text');

      // Split the pasted text into lines
      let lines = pastedText.split('\n');
      lines = lines.filter((str) => str.trim() !== '');

      if (lines.length > 0) {
         // Ensure the first line becomes the current items value
         const { currentItem } = this.state;
         currentItem['label'] = lines[0];
         const newItem = { ...currentItem };
         this.setState({ newItem, broadcast: true }, () => {
            lines.shift();

            if (lines.length > 0) {
               if (onCopy) {
                  // Copy them across in reverse order so it maintains
                  // the order in the list.
                  for (var i = lines.length - 1; i >= 0; i--) {
                     (function (line) {
                        var a = { item: { ...newItem, label: line } };

                        setTimeout(() => {
                           onCopy(a);
                        }, 10);
                     })(lines[i]);
                  }
               }
            }
         });
      }
   };

   _onFieldHintChange = (e) => {
      const { currentItem } = this.state;

      currentItem['hint'] = e.target.value;

      this.setState({ currentItem, broadcast: true });
   };

   validate(currentItem) {
      let valid = false;
      let validations = {};
      let o = { ...currentItem };

      try {
         //cast(p, [types.email, types.name, types.password, types.confirm]);

         valid = true;
      } catch (err) {
         validations = _.isObject(err.message)
            ? err.asJSON
               ? err.message
               : JSON.stringify(err.message)
            : err.asJSON
            ? JSON.parse(err.message)
            : err.message;

         valid = false;
      }

      return { valid, validations, currentItem };
   }

   _onMayRaiseIssuesChanged = ({ event }) => {
      const mayRaiseIssues = event.target.checked;

      const { currentItem } = this.state;

      currentItem['mayRaiseIssues'] = mayRaiseIssues;

      this.setState({ currentItem, broadcast: true });
   };

   _onMayBeSkippedChanged = ({ event }) => {
      const mayBeSkipped = event.target.checked;

      const { currentItem } = this.state;

      currentItem['idRequiredType'] = mayBeSkipped ? fieldRequiredTypes.SKIPABLE.id : fieldRequiredTypes.ALWAYS.id;

      this.setState({ currentItem, broadcast: true });
   };

   _onAllowNotesChanged = ({ event }) => {
      const allowNotes = event.target.checked;

      const { currentItem } = this.state;

      currentItem['allowNotes'] = allowNotes;

      this.setState({ currentItem, broadcast: true });
   };

   _onEditRulesDone = ({ rules }) => {
      const { currentItem } = this.state;

      const clonedItem = { ...currentItem };

      clonedItem.options = clonedItem.options || {};

      clonedItem.options.rules = rules;

      this.setState({ currentItem: clonedItem, broadcast: true });
   };

   _onDataSourceChanged = ({ noDataSource }) => {
      const { currentItem } = this.state;

      let options = currentItem.options || {};
      currentItem['options'] = options;

      let binding = options && options.binding ? options.binding : _.cloneDeep(blankOptionsBinding);
      currentItem.options.binding = binding;
      binding.noDataSource = noDataSource;

      this.setState({ currentItem, broadcast: true });
   };

   _onAllowMultipleChanged = ({ currentItem, event }) => {
      const allowMultiple = event.target.checked;

      currentItem['options'] = currentItem.options || {};

      currentItem.options['allowMultiple'] = allowMultiple;

      this.setState({ currentItem, broadcast: true });
   };

   _onDateTimeTypeChanged = (event, value) => {
      const { currentItem } = this.state;

      currentItem['options'] = currentItem.options || {};

      const idDateTimeType = parseInt(value, 10);

      currentItem.options.idDateTimeType = idDateTimeType;

      this.setState({ currentItem, broadcast: true });
   };

   renderDateTimeProps() {
      const { currentItem, editingOptions } = this.state;
      const { options } = currentItem;
      let idDateTimeType = options ? options.idDateTimeType || datetimeFormItemTypes.DATE.id : datetimeFormItemTypes.DATE.id;

      return (
         <>
            <div className='d-flex justify-content-start flex-row align-items-center'>
               <span style={{ marginLeft: '8px', minWidth: '60px' }}>Capture</span>
               <RadioGroup
                  className='d-flex flex-row justify-content-center date-radio-group'
                  aria-label='customer type'
                  name='idDateTimeType'
                  value={idDateTimeType.toString()}
                  onChange={this._onDateTimeTypeChanged}>
                  <FormControlLabel value={datetimeFormItemTypes.DATE.id.toString()} control={<Radio color='primary' />} label={'Date'} />
                  <FormControlLabel value={datetimeFormItemTypes.TIME.id.toString()} control={<Radio color='primary' />} label={'Time'} />
                  <FormControlLabel
                     value={datetimeFormItemTypes.DATETIME.id.toString()}
                     control={<Radio color='primary' />}
                     label={'Date & Time'}
                  />
               </RadioGroup>
            </div>
         </>
      );
   }

   _onUseButtonsChanged = ({ currentItem, event }) => {
      const useButtons = event.target.checked;

      currentItem['options'] = currentItem.options || {};

      const idOptionsDisplayType = useButtons ? optionsFormItemDisplayTypes.BUTTONS.id : optionsFormItemDisplayTypes.BULLETS.id;

      currentItem.options.idOptionsDisplayType = idOptionsDisplayType;

      this.setState({ currentItem, broadcast: true });
   };

   renderOptionsProps() {
      const { currentItem, editingOptions } = this.state;
      const { availableLocations, availableTags } = this.props;
      const { options } = currentItem;
      let allowMultiple = options ? options.allowMultiple : false;
      let idOptionsDisplayType = options
         ? options.idOptionsDisplayType || optionsFormItemDisplayTypes.BULLETS.id
         : optionsFormItemDisplayTypes.BULLETS.id;
      let binding = options && options.binding ? options.binding : _.cloneDeep(blankOptionsBinding);
      const noDataSource = binding.noDataSource;

      let allowButtons = idOptionsDisplayType == optionsFormItemDisplayTypes.BUTTONS.id;

      return (
         <div className='options'>
            <div className='options-from'>
               <span style={{ marginLeft: '8px', minWidth: '60px' }}>Options from</span>
               <FieldDataSourceEditor
                  noDataSource={noDataSource}
                  onChange={this._onDataSourceChanged}
                  availableLocations={availableLocations}
                  availableTags={availableTags}
               />
            </div>
            <>
               <div className='d-flex justify-content-start flex-row align-items-center'>
                  <div>
                     <Checkbox checked={allowButtons} onChange={(e) => this._onUseButtonsChanged({ currentItem, event: e })} />
                     Show options as buttons
                  </div>
               </div>
            </>
            <>
               <div className='d-flex justify-content-start flex-row align-items-center'>
                  <div>
                     <Checkbox checked={allowMultiple} onChange={(e) => this._onAllowMultipleChanged({ currentItem, event: e })} />
                     Allow multiple answers
                  </div>
               </div>
            </>
         </div>
      );
   }

   renderRepeaterProps() {
      const { currentItem } = this.state;
      const { availableLocations, availableTags } = this.props;

      const { options } = currentItem;
      let idRepeaterType = options ? options.idRepeaterType || repeaterFormItemTypes.LIST.id : repeaterFormItemTypes.LIST.id;
      let idRepeaterCadence = options ? options.idRepeaterCadence || 1 : 1;
      let timesToRepeat = options ? options.timesToRepeat || 1 : 1;
      let binding = options && options.binding ? options.binding : { noDataSource: null };
      const noDataSource = binding.noDataSource;

      const isList = idRepeaterType == 1;

      return (
         <div className='repeater-options'>
            <div className='d-flex justify-content-start flex-row align-items-center'>
               <span style={{ marginLeft: '8px', marginRight: '10px', minWidth: '60px' }}>Type</span>
               <RadioGroup
                  className='d-flex flex-row'
                  aria-label='customer type'
                  name='idRepeaterType'
                  value={idRepeaterType.toString()}
                  onChange={this._onRepeaterTypeChanged}>
                  <FormControlLabel value={repeaterFormItemTypes.LIST.id.toString()} control={<Radio color='primary' />} label={'List'} />
                  <FormControlLabel
                     value={repeaterFormItemTypes.FREE_FLOW.id.toString()}
                     control={<Radio color='primary' />}
                     label={'Free flow'}
                  />
               </RadioGroup>
            </div>
            {isList && (
               <div className='data-source-editor-row'>
                  <span style={{ marginLeft: '8px', minWidth: '60px' }}>List from</span>
                  <FieldDataSourceEditor
                     noDataSource={noDataSource}
                     onChange={this._onDataSourceChanged}
                     availableLocations={availableLocations}
                     availableTags={availableTags}
                  />
               </div>
            )}
            <div className='d-flex justify-content-start flex-row align-items-center'>
               <span style={{ marginLeft: '8px', minWidth: '60px' }}>Requires</span>
               <RepeatTimesSelector
                  onChange={this._repeatTimesChange}
                  idRepeaterType={idRepeaterType}
                  idRepeaterCadence={idRepeaterCadence}
                  timesToRepeat={timesToRepeat}
               />
            </div>
         </div>
      );
   }

   _repeatTimesChange = (d) => {
      const { currentItem } = this.state;

      currentItem['options'] = currentItem.options || {};

      currentItem.options.idRepeaterCadence = d.idRepeaterCadence;
      currentItem.options.timesToRepeat = d.timesToRepeat;

      this.setState({ currentItem, broadcast: true });
   };

   _onRepeaterTypeChanged = (event, value) => {
      const { currentItem } = this.state;

      currentItem['options'] = currentItem.options || {};

      const idRepeaterType = parseInt(value, 10);

      switch (true) {
         case idRepeaterType === repeaterFormItemTypes.LIST.id:
            // lists don't have any number of entries
            // so set back to foreach
            if (currentItem.options.idRepeaterCadence == repeaterCadenceTypes.ANY_NUMBER_OF.id) {
               currentItem.options.idRepeaterCadence = repeaterCadenceTypes.FOREACH.id;
            }
            break;

         case idRepeaterType === repeaterFormItemTypes.FREE_FLOW.id:
            // free flow don't have foreach
            // so set back to any number of entries
            if (currentItem.options.idRepeaterCadence == repeaterCadenceTypes.FOREACH.id) {
               currentItem.options.idRepeaterCadence = repeaterCadenceTypes.ANY_NUMBER_OF.id;
            }

            let binding = _.cloneDeep(blankOptionsBinding);
            currentItem.options.binding = binding;

            break;
         default:
            break;
      }

      currentItem.options.idRepeaterType = idRepeaterType;

      this.setState({ currentItem, broadcast: true });
   };

   _onFieldPrefixChange = (e) => {
      const { currentItem } = this.state;

      currentItem['options'] = currentItem.options || {};

      currentItem.options.prefix = e.target.value;

      this.setState({ currentItem, broadcast: true });
   };

   _onFieldSuffixChange = (e) => {
      const { currentItem } = this.state;

      currentItem['options'] = currentItem.options || {};

      currentItem.options.suffix = e.target.value;

      this.setState({ currentItem, broadcast: true });
   };

   _onFieldPlacesChange = (event) => {
      const { currentItem } = this.state;

      const places = parseInt(event.target.value, 10);

      currentItem['options'] = currentItem.options || {};

      currentItem.options.numericality = currentItem.options.numericality ? currentItem.options.numericality : {};
      currentItem.options.numericality.places = places;

      this.setState({ currentItem, broadcast: true });
   };

   renderNumberProps() {
      const { currentItem, editingOptions } = this.state;
      const { allowMultiple, options } = currentItem;
      let numericality = options ? options.numericality || {} : {};
      let places = typeof numericality.places !== 'undefined' ? parseInt(numericality.places, 10) : 0;
      let prefix = options ? options.prefix || '' : '';
      let suffix = options ? options.suffix || '' : '';

      return (
         <Fragment>
            <div className='d-flex justify-content-start flex-row'>
               <TextField
                  id='field-prefix'
                  label='prefix'
                  margin='normal'
                  placeholder='eg £'
                  InputLabelProps={{ shrink: true }}
                  defaultValue={prefix}
                  onChange={(e) => this._onFieldPrefixChange(e)}
                  variant='outlined'
               />
               <span className='answer-block'>Answer</span>
               <TextField
                  id='field-suffix'
                  label='suffix'
                  placeholder='eg °C'
                  InputLabelProps={{ shrink: true }}
                  margin='normal'
                  defaultValue={suffix}
                  onChange={(e) => this._onFieldSuffixChange(e)}
                  variant='outlined'
               />
            </div>
            <TextField
               id='field-places'
               select
               label={'decimals'}
               value={places}
               onChange={(e) => {
                  this._onFieldPlacesChange(e);
               }}
               //InputLabelProps={{ shrink: !!timezoneCode }}
               SelectProps={{
                  native: true
               }}
               margin='normal'
               variant='outlined'
               className='select-places'>
               {decimalPlacesList.map((option) => (
                  <option key={'places-opt-' + option.id} value={option.id}>
                     {option.name}
                  </option>
               ))}
            </TextField>
         </Fragment>
      );
   }

   _onAllowVideoChanged = ({ currentItem, event }) => {
      currentItem['options'] = currentItem.options || {};

      currentItem.options.allowVideo = event.target.checked;

      this.setState({ currentItem, broadcast: true });
   };

   _onAllowLibraryChanged = ({ currentItem, event }) => {
      currentItem['options'] = currentItem.options || {};

      currentItem.options.allowLibrary = event.target.checked;

      this.setState({ currentItem, broadcast: true });
   };

   _onSubFormChanged = ({ currentItem, event }) => {
      currentItem['options'] = currentItem.options || {};

      currentItem.options.isSubform = event.target.checked;

      this.setState({ currentItem, broadcast: true });
   };

   renderPhotoProps() {
      const { currentItem } = this.state;

      const { options } = currentItem;
      let allowVideo = options ? options.allowVideo || false : false;
      let allowLibrary = options ? options.allowLibrary || false : false;

      return (
         <Fragment>
            <div className='d-flex justify-content-start flex-row align-items-center'>
               <Checkbox
                  style={{ marginRight: 8 }}
                  checked={allowVideo}
                  onChange={(e) => this._onAllowVideoChanged({ currentItem, event: e })}
               />
               Allow videos
            </div>
            <div className='d-flex justify-content-start flex-row align-items-center'>
               <Checkbox
                  style={{ marginRight: 8 }}
                  checked={allowLibrary}
                  onChange={(e) => this._onAllowLibraryChanged({ currentItem, event: e })}
               />
               Allow uploads from device's media library
            </div>
         </Fragment>
      );
   }

   renderSectionProps() {
      const { currentItem } = this.state;

      const { options } = currentItem;
      let isSubform = options ? options.isSubform || false : false;
      let lockedSubForm = options ? options.isSubformLocked || false : false;
      let lockedCaption = lockedSubForm
         ? 'Remove the nested sections within this section to change this.'
         : isSubform
         ? 'Displays in its own page, unselect to display inline'
         : 'Displays inline, select to display in its own page';

      return (
         <Fragment>
            <div className='d-flex justify-content-start flex-row align-items-center'>
               <Tooltip title={lockedCaption} placement='right'>
                  <div>
                     <Checkbox
                        disabled={lockedSubForm}
                        //style={{ marginRight: 8 }}
                        checked={isSubform}
                        onChange={(e) => this._onSubFormChanged({ currentItem, event: e })}
                     />
                     Displays in its own page
                  </div>
               </Tooltip>
            </div>
         </Fragment>
      );
   }

   renderSpecificType({ idItemType }) {
      switch (idItemType) {
         case formItemTypes.OPTIONS.id:
            return this.renderOptionsProps();

         case formItemTypes.NUMBER.id:
            return this.renderNumberProps();

         case formItemTypes.DATETIME.id:
            return this.renderDateTimeProps();

         case formItemTypes.PHOTO.id:
            return this.renderPhotoProps();

         case formItemTypes.SECTION.id:
            return this.renderSectionProps();

         case formItemTypes.REPEATER.id:
            return this.renderRepeaterProps();

         default:
            return null;
      }
   }

   renderIcon(idItemType) {
      switch (idItemType) {
         case formItemTypes.TODO.id:
            return <ToDoFormItemIcon />;

         case formItemTypes.OPTIONS.id:
            return <OptionsFormItemIcon />;

         case formItemTypes.NUMBER.id:
            return <NumberFormItemIcon />;

         case formItemTypes.TEXT.id:
            return <TextFormItemIcon />;

         case formItemTypes.PHOTO.id:
            return <PhotoFormItemIcon />;

         case formItemTypes.DATETIME.id:
            return <DateFormItemIcon />;

         case formItemTypes.SECTION.id:
            return <SectionFormItemIcon />;

         case formItemTypes.REPEATER.id:
            return <RepeaterFormItemIcon />;

         default:
            return null;
      }
   }

   _onDeleteRequested = () => {
      this.setState({ isDeleting: true });
   };

   _onDeleteConfirmed = () => {
      const { currentItem } = this.state;

      const onDelete = this.props.onDelete;
      if (onDelete) {
         onDelete({ item: currentItem });
      }

      this.setState({ isDeleting: false });
   };

   _onDeleteCancelled = () => {
      this.setState({ isDeleting: false });
   };

   _onCopyClicked = () => {
      const { currentItem } = this.state;

      const onCopy = this.props.onCopy;
      if (onCopy) {
         onCopy({ item: currentItem });
      }
   };

   renderProps() {
      const { currentItem } = this.state;
      let itemData = currentItem || {};
      const { label, idItemType, idRequiredType, allowNotes } = itemData;
      const mayRaiseIssues = itemData.mayRaiseIssues || false;
      const skippedTooltip =
         idRequiredType === fieldRequiredTypes.SKIPABLE.id
            ? 'unselect to make this question mandatory'
            : 'select to make this question optional';
      const raiseIssuesTooltip = mayRaiseIssues ? 'unselect to disallow raising issues' : 'select to allow raising issues';

      return (
         <div className='d-flex justify-content-start flex-column'>
            <div className='d-flex justify-content-start align-items-center flex-row'>
               {/* {this.renderIcon(idItemType)} */}
               <TextField
                  id='field-label'
                  label='Caption'
                  margin='normal'
                  placeholder='Start typing...'
                  //multiline
                  style={{ marginLeft: 10, flex: 1 }}
                  value={label}
                  onChange={(e) => this._onFieldLabelChange(e)}
                  variant='outlined'
                  onPaste={this._onFieldLabelPaste}
                  //autoFocus={true}
                  InputProps={{
                     startAdornment: (
                        <InputAdornment position='start'>
                           {this.renderIcon(idItemType)}
                           <Divider
                              style={{
                                 height: 28,
                                 margin: 4
                              }}
                              orientation='vertical'
                           />
                        </InputAdornment>
                     )
                  }}
                  inputRef={this.captionRef}
               />
               {currentItem && (
                  <div>
                     <Tooltip title='Remove this item'>
                        <IconButton className='btn-delete' edge='end' onClick={() => this._onDeleteRequested()}>
                           <DeleteIcon />
                        </IconButton>
                     </Tooltip>
                     <Tooltip title='Clone this item'>
                        <IconButton className='btn-copy' edge='end' onClick={() => this._onCopyClicked()}>
                           <CopyIcon />
                        </IconButton>
                     </Tooltip>
                  </div>
               )}
            </div>
            <Divider
               style={{
                  margin: 4
               }}
            />
            {this.renderSpecificType({ idItemType })}
            <div className='d-flex justify-content-start flex-row align-items-center'>
               <Tooltip title={skippedTooltip}>
                  <div>
                     <Checkbox
                        //style={{ marginRight: 8 }}
                        checked={idRequiredType === fieldRequiredTypes.SKIPABLE.id}
                        onChange={(e) => this._onMayBeSkippedChanged({ event: e })}
                        //onChange={(e) => this._onAllowMultipleChanged({ currentItem, event: e })}
                     />
                     May be skipped
                  </div>
               </Tooltip>
            </div>
            <div className='d-flex justify-content-start flex-row align-items-center'>
               <div>
                  <Checkbox checked={allowNotes} onChange={(e) => this._onAllowNotesChanged({ event: e })} />
                  Allow notes
               </div>
            </div>

            {/* <div className='d-flex justify-content-start flex-row align-items-center'>
               <Tooltip title={raiseIssuesTooltip}>
                  <div>
                     <Checkbox
                        //style={{ marginRight: 8 }}
                        checked={mayRaiseIssues}
                        onChange={(e) => this._onMayRaiseIssuesChanged({ event: e })}
                        //onChange={(e) => this._onAllowMultipleChanged({ currentItem, event: e })}
                     />
                     Allow raising issues
                  </div>
               </Tooltip>
            </div> */}
         </div>
      );
   }

   _onResourceListChanged = ({ resources }) => {
      const { currentItem } = this.state;

      currentItem['resources'] = resources;

      this.setState({ currentItem, broadcast: true });
   };

   renderLogic() {
      const { currentItem } = this.state;

      let options = currentItem.options || {};

      let binding = options && options.binding ? options.binding : _.cloneDeep(blankOptionsBinding);
      const noDataSource = binding.noDataSource;

      if (noDataSource == null) {
         return <></>;
      }

      let rules = currentItem.options.rules || [];

      return (
         <>
            <DataSourceRulesEditor rules={rules} onChange={this._onEditRulesDone} />
         </>
      );
   }

   renderGuidance() {
      const { currentItem } = this.state;
      const { availableLocations, availableTags } = this.props;

      let itemData = currentItem || {};

      const { hint, resources } = itemData;

      let hintVal = hint || '';

      return (
         <div className='d-flex justify-content-start flex-column'>
            <TextField
               autoFocus={true}
               id='field-hint'
               InputLabelProps={{ shrink: true }}
               label='Instructions'
               placeholder={'eg. explain how to complete...'}
               margin='normal'
               value={hintVal}
               onChange={(e) => this._onFieldHintChange(e)}
               variant='outlined'
            />
            <FileResourceList
               minNoAttachments={0}
               resources={resources}
               availableLocations={availableLocations}
               availableTags={availableTags}
               readOnly={false}
               onChange={this._onResourceListChanged}
               addBlankRow={false}
            />
         </div>
      );
   }

   _onTabChange = (event, value) => {
      this.setState({ tabState: value });
   };

   renderForState(tabState) {
      return <div className='prop-window'>{this.stateRenders[tabState]()}</div>;
   }

   renderTabs() {
      const { tabState, currentItem } = this.state;
      let itemData = currentItem || {};
      const { idItemType } = itemData;

      const tabClasses = 'tabs-bar';
      const showLogic = idItemType === formItemTypes.OPTIONS.id;

      return (
         <Fragment>
            <Tabs
               className={tabClasses}
               scrollButtons='on'
               indicatorColor='primary'
               textColor='primary'
               value={tabState}
               onChange={this._onTabChange}>
               <Tab value={tabStates.PROPS} className='tab' label='Setup' />
               <Tab value={tabStates.GUIDANCE} className='tab' label='Instructions' />
               {showLogic && <Tab value={tabStates.LOGIC} className='tab' label='Logic' />}
            </Tabs>
         </Fragment>
      );
   }

   render() {
      const { currentItem, isDeleting, tabState } = this.state;
      let itemData = currentItem || {};

      const { label, hint, idRequiredType, idItemType } = itemData;

      return (
         <div className='FieldPropertyEditor'>
            {!currentItem && <span></span>}

            {currentItem && this.renderForState(tabState)}
            {currentItem && this.renderTabs()}
            {isDeleting && (
               <SweetAlert
                  show={true}
                  warning
                  showCancel
                  confirmBtnText={'Yes'}
                  cancelBtnText={'No'}
                  confirmBtnBsStyle='warning'
                  cancelBtnBsStyle='default'
                  title={'Remove Field?'}
                  onConfirm={this._onDeleteConfirmed}
                  onCancel={this._onDeleteCancelled}>
                  <Typography>Are you sure you want to remove this field</Typography>
               </SweetAlert>
            )}
         </div>
      );
   }
}

FieldPropertyEditor.defaultProps = {
   onChange: ({ item }) => {},
   onDelete: ({ item }) => {},
   onCopy: ({ item }) => {},
   selectedItem: null,
   availableLocations: [],
   availableTags: []
};

FieldPropertyEditor.propTypes = {};

export default FieldPropertyEditor;
