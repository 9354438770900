const BaseEntry = require('./BaseEntry');
const constants = require('../../../constants');
const _ = require('../../../lodash');
const { answerStates, repeaterFormItemTypes } = constants;
class RepeaterGroupEntry extends BaseEntry {
   constructor(aggregateRoot, componentData, items, entries, ruleEvaluator) {
      super(aggregateRoot, componentData, items, entries, ruleEvaluator);
   }

   changeRepeaterGroupListItem({ actionedOn, idListItem, geoLat, geoLong, geoTimeStamp }) {
      const item = this.item;

      const noResponse = item.response ? item.response.no : 1;
      const newItemState = item.state; // No change....

      var p = {
         idItem: this.idItem,
         state: newItemState,
         actionedOn,
         noResponse,
         val: idListItem,
         noSubmissionItem: item.no,
         noSubmission: this._aggregateRoot.getSubmissionNo(),
         geoLat,
         geoLong,
         geoTimeStamp
      };

      this.apply('REPEATER_GROUP_LIST_ITEM_CHANGED', p);

      item.response = item.response || {};
      item.response.no = noResponse;
      item.response.val = idListItem;
      item.response.actionedOn = actionedOn;
      item.state = newItemState;
      item.geoLat = geoLat;
      item.geoLong = geoLong;
      item.geoTimeStamp = geoTimeStamp;
   }

   lock() {
      const item = this.item;
      const { state } = item;
      item.state = state | answerStates.LOCKED;

      for (var i = 0; i < this.children.length; i++) {
         const e = this.children[i];
         e.lock();
      }
   }

   unlock() {
      const item = this.item;
      const { state } = item;
      item.state = state & ~answerStates.LOCKED;

      for (var i = 0; i < this.children.length; i++) {
         const e = this.children[i];
         e.unlock();
      }
   }

   hasFailures() {
      let isFailed = false;

      for (var i = 0; i < this.children.length; i++) {
         const e = this.children[i];
         if (e.hasFailures()) {
            isFailed = true;
         }
      }
      return isFailed;
   }

   countFlags() {
      let flagged = false;
      let noOfFlags = 0;

      for (var i = 0; i < this.children.length; i++) {
         const e = this.children[i];

         const flagResult = e.countFlags();

         if (flagResult.flagged) {
            flagged = true;
            noOfFlags = noOfFlags + 1;
         }
      }

      return {
         flagged,
         noOfFlags
      };
   }

   validate() {
      let isValid = true;

      for (var i = 0; i < this.children.length; i++) {
         const e = this.children[i];
         if (!e.validate()) {
            isValid = false;
         }
      }
      return isValid;
   }

   score() {
      let overallScore = null;
      let overallMaxScore = null;

      for (var i = 0; i < this.children.length; i++) {
         const e = this.children[i];
         const scoreResult = e.score();

         overallScore =
            scoreResult.score == null ? overallScore : overallScore == null ? scoreResult.score : overallScore + scoreResult.score;

         overallMaxScore =
            scoreResult.maxScore == null
               ? overallMaxScore
               : overallMaxScore == null
               ? scoreResult.maxScore
               : overallMaxScore + scoreResult.maxScore;
      }

      if (this.item) {
         this.item.score = overallScore;
         this.item.maxScore = overallMaxScore;
      }

      return {
         score: overallScore,
         maxScore: overallMaxScore
      };
   }

   refresh() {
      this.item.label = this.getFriendlyValue();
   }

   getGroupName() {
      let name = null;

      if (this.item.response) {
         /*
         console.log('---- entries');
         console.log(JSON.stringify(this.entries, null, 2));
         console.log('----');

         const repeaterEntry = _.find(this.entries, (x) => {
            return x.item.id === this.item.idParent;
         });

         console.log(JSON.stringify(repeaterEntry, null, 2));
         console.log('========');

         return this.item.label;
         */

         const repeater = _.find(this.items, (x) => {
            return x.id === this.item.idParent;
         });

         if (repeater) {
            if (!this.isOfListType(repeater)) {
               return null;
            }

            const { idField, txtField } = repeater.options.binding;

            if (repeater.options.listOptions.length == 0) {
               throw new Error('listOptions are empty. Datasources are most likely not loaded.');
            }

            const listItem = _.find(repeater.options.listOptions, (x) => {
               return x[idField].toString() == this.item.response.val.toString();
            });

            if (!listItem) {
               throw new Error(
                  `listItem is undefined. getGroupName() failed. Response value: ${
                     this.item.response.val
                  }. Available listOptions: ${JSON.stringify(repeater.options.listOptions, null, 2)}`
               );
            }

            name = listItem[txtField];
         }
      }

      return name;
   }

   isOfListType(repeater) {
      return repeater.options && repeater.options.idRepeaterType == repeaterFormItemTypes.LIST.id;
   }

   getFriendlyValue() {
      let label = '';

      let groupName = this.getGroupName();

      if (groupName != null) {
         label = groupName;
      }

      for (var i = 0; i < this.children.length; i++) {
         const e = this.children[i];
         let childLabel = e.getFriendlyValue();
         if (childLabel != '') {
            if (label.length > 0) {
               label += ' | ';
            }
            label += childLabel;
         }
      }

      return label;
   }
}

module.exports = RepeaterGroupEntry;
