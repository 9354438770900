import Chip from '@material-ui/core/Chip';
import classNames from 'classnames';
import React from 'react';
import { Component } from '../../../client';
import { lodash as _, constants, domain } from '../../../common';
import PeopleSelector from '../../../components/people/PeopleSelector';
import { Popover, PopoverBody } from '../../popovers';

import ActionButton from '../../ux/Buttons/ActionButton';
import AssignmentButton from './components/AssignmentButton';

const { whoAssignmentTypes, whoUnionType } = constants;
const { WhoCaptionBuilder } = domain;

const inputStates = {
   SELECT_WHO: 1,
   DONE: 2
};

class PeopleAssigner extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);

      this.stateRenders = {};
      this.stateRenders[inputStates.SELECT_WHO] = this.renderWhoSelection.bind(this);

      this._onPopoverKeyDown = this._onPopoverKeyDown.bind(this);
      this._onAssignmentTypeChanged = this._onAssignmentTypeChanged.bind(this);

      this.captionBuilder = new WhoCaptionBuilder();
   }

   buildFromProps(props) {
      let staff = props.staff.map((l) => {
         const { name, noUser } = l.user;

         return { name, noUser };
      });

      var s = {
         who: _.cloneDeep(props.who),
         editing: props.openForEdit,
         shouldValidate: true,
         showDone: false,
         broadcast: false,
         valid: false,
         inputState: inputStates.SELECT_WHO,
         staff
      };

      return _.merge({}, s);
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const currentWho = this.state.who;
      const nextWho = nextProps.who;
      const current = this.state;
      const next = nextProps;
      const currentStaff = this.state.staff;
      const nextStaff = nextProps.staff;

      if (
         !currentWho ||
         (nextWho && !_.isEqual(currentWho, nextWho)) ||
         !current ||
         (next && current.openForEdit != next.openForEdit) ||
         (nextStaff && !_.isEqual(currentStaff, nextStaff))
      ) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   componentDidUpdate() {
      const { broadcast, shouldValidate, who } = this.state;

      if (shouldValidate) {
         var o = this.validate({ who });
         this.setState({ ...o, shouldValidate: false });
      }

      if (broadcast) {
         const onChange = this.props.onChange;
         if (onChange) {
            onChange({ who });
         }

         this.setState({ broadcast: false });
      }
   }

   validate({ who }) {
      let valid = true;

      const { noWhoUnionType, noWhoAssignmentType, positions, teams, staff } = who;

      const hasPositions = positions && positions.length > 0;
      const hasTeams = teams && teams.length > 0;
      const hasStaff = staff && staff.length > 0;

      valid = (hasPositions || hasTeams || hasStaff) && noWhoUnionType != whoUnionType.NONE.id;

      if (!valid) {
         valid = noWhoAssignmentType == whoAssignmentTypes.ALL_STAFF.id;
      }

      //TODO - flesh out

      return { valid, who };
   }

   toggleEditing = (e, changed = false) => {
      const { editing } = this.state;
      const { onPopoutClosed } = this.props;

      const newEditing = !editing;
      if (!newEditing) {
         if (onPopoutClosed) {
            onPopoutClosed();
         }
      }

      const s = !changed ? this.buildFromProps(this.props) : this.state;

      this.setState({
         ...s,
         editing: newEditing,
         inputState: inputStates.SELECT_WHO
      });
   };

   getState = () => {
      return _.cloneDeep(this.state);
   };

   buildCaption(who) {
      const { teams, positions, staff, unassignedLabel } = this.props;

      const whoCaption = this.captionBuilder.build({
         who,
         unassignedLabel,
         availablePositions: positions,
         availableTeams: teams,
         availableStaffMembers: staff
      });
      return `${whoCaption}`;
   }

   setNextInputState = (nextInputState) => {
      if (nextInputState === inputStates.DONE) {
         this.setState({ broadcast: true, inputState: inputStates.SELECT_WHO, editing: false }, () => {
            this.toggleEditing(null, true);
         });
      } else {
         this.setState({ inputState: nextInputState });
      }
   };

   getNextInputState = () => {
      const { inputState, who } = this.state;

      let newState = inputStates.DONE;

      if (inputState === inputStates.SELECT_WHO) {
         newState = inputStates.DONE;
      }

      return newState;
   };

   _onPopoverKeyDown(evt) {
      if (evt.key === 'Enter') {
         const { valid } = this.state;
         if (valid) {
            this._onDone();
         }
      }
      if (evt.key === 'Escape') {
         this.toggleEditing();
      }
   }

   _onAssignmentTypeChanged(noWhoUnionType) {
      const { who } = this.state;

      who.noWhoUnionType = noWhoUnionType;

      this.setState({ who, shouldValidate: true });
   }

   _onUnionTypeChanged = (evt) => {
      const { who } = this.state;

      const noWhoUnionType = parseInt(evt.target.value, 10);

      who.noWhoUnionType = noWhoUnionType;

      this.setState({ who, shouldValidate: true });
   };

   _onPeopleSelectionChanged = (who) => {
      let noWhoAssignmentType = 0;

      noWhoAssignmentType =
         who.positions.length > 0
            ? noWhoAssignmentType | whoAssignmentTypes.SPECIFIC_POSITIONS.id
            : noWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_POSITIONS.id;
      noWhoAssignmentType =
         who.teams.length > 0
            ? noWhoAssignmentType | whoAssignmentTypes.SPECIFIC_TEAMS.id
            : noWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_TEAMS.id;
      noWhoAssignmentType =
         who.staff.length > 0
            ? noWhoAssignmentType | whoAssignmentTypes.SPECIFIC_STAFF.id
            : noWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_STAFF.id;

      // handles the case where everyone has been selected
      if (noWhoAssignmentType == 0) {
         noWhoAssignmentType = who.noWhoAssignmentType;
      } else {
         who.noWhoAssignmentType = noWhoAssignmentType;
      }

      this.setState({ who, shouldValidate: true });
   };

   _onSelectingPeople = ({ isOpen }) => {
      this.setState({ showDone: isOpen });
   };

   renderWhoSelection() {
      const { staff, who, valid, showDone } = this.state;
      const { teams, positions, showUnionSelector, doneCaption } = this.props;
      const { noWhoUnionType } = who;

      const val = noWhoUnionType != null ? noWhoUnionType : whoUnionType.ANY.id;
      const disableDone = !valid;

      let selectorLabel = '';
      if (!showUnionSelector) {
         selectorLabel = whoUnionType.ANY.id === val ? 'Any one of ' + selectorLabel : 'All of ' + selectorLabel;
      }

      return (
         <PopoverBody>
            <div className='who-selection'>
               <div className='who-content'>
                  <PeopleSelector
                     label={selectorLabel}
                     teams={teams}
                     positions={positions}
                     staff={staff}
                     who={who}
                     onSelecting={this._onSelectingPeople}
                     onChange={this._onPeopleSelectionChanged}
                  />
                  <div
                     style={{
                        paddingTop: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        minWidth: '200px'
                     }}>
                     {showUnionSelector && !showDone && (
                        <AssignmentButton
                           disabled={disableDone}
                           noWhoUnionType={val}
                           onAssignmentTypeChange={this._onAssignmentTypeChanged}
                           onAssign={this._onDone}
                        />
                     )}
                     {!showUnionSelector && !showDone && <ActionButton onClick={this._onDone}>Assign</ActionButton>}
                     {showDone && (
                        <ActionButton color='primary' onClick={this._onDone}>
                           {doneCaption}
                        </ActionButton>
                     )}
                  </div>
               </div>
            </div>
         </PopoverBody>
      );
   }

   _onDone = () => {
      this.setNextInputState(inputStates.DONE);
   };

   _onTagSelected = (data) => {
      const { who } = this.state;
      let tag = null;
      if (data.tags.length > 0) {
         tag = data.tags[0];
      }
      who.atTaggedAs = tag;

      this.setState({ who });
   };

   renderForState(inputState) {
      return <div className='canvas'>{this.stateRenders[inputState]()}</div>;
   }

   _onChipSelected = ({ evt, who }) => {
      const { onChipSelected } = this.props;

      onChipSelected({ evt, who });
   };

   render() {
      const { editing, inputState, staff } = this.state;
      const { id, label, who, readOnly, showAsChip, positions, teams, onChipSelected } = this.props;

      if (!who || (who && positions.length == 0 && teams.length == 0 && staff.length == 0)) {
         return <div></div>;
      }

      const className = classNames('PeopleAssigner');
      const caption = this.buildCaption(who);
      const showLabel = label != null;
      const popupTarget = id ? id + '-set-who-spn' : 'set-who-spn';

      return (
         <div className={className}>
            {readOnly && !showAsChip && (
               <div className='assign-control'>
                  {showLabel && <label>{label}</label>}
                  <span>{caption}</span>
               </div>
            )}
            {readOnly && showAsChip && (
               <Chip
                  size='small'
                  color={'secondary'}
                  variant='outlined'
                  className={'info-chip'}
                  label={caption}
                  onClick={onChipSelected ? (e) => this._onChipSelected({ evt: e, who }) : undefined}
               />
            )}

            {!readOnly && (
               <div className='assign-control'>
                  {showLabel && <label>{label}</label>}
                  <div className='editable-caption-row'>
                     <span id={popupTarget} className='filter-link' onClick={this.toggleEditing}>
                        {caption}
                     </span>
                  </div>
               </div>
            )}
            {!readOnly && editing && (
               <Popover
                  placement='bottom'
                  isOpen={editing}
                  target={popupTarget}
                  className='who-editing-popover'
                  toggle={this.toggleEditing}
                  onKeyDown={this._onPopoverKeyDown}>
                  {this.renderForState(inputState)}
               </Popover>
            )}
         </div>
      );
   }
}

PeopleAssigner.defaultProps = {
   showUnionSelector: true,
   teams: [],
   positions: [],
   staff: [],
   unassignedLabel: 'not yet assigned to anyone',
   doneCaption: "I'm done"
   /*
	
	allLocationsOption: 'anywhere',
	allLocationsLabel: 'works anywhere',
	atCertainLocationsOption: 'at certain locations',
	workQuestionLabel: 'Where can they work?',
	actionWord: 'works',
	forceSpecificLocation: false,
	who: null,
	availableTags: [],
	readOnly: false,
	showAsChip: false,
	openForEdit: false,
	onChipSelected: null,
	onChange: () => {},
	onPopoutClosed: () => {},
	label: null,
	id: null,
	availableLocations: []
	*/
   /*
	availableTags: [
		{ noTag: 1, name: 'unit' },
		{ noTag: 2, name: 'region' }
	],
	*/
   /*
	availableLocations: [
		{ no: 1, name: 'KZN', fullName: 'KZN' },
		{ no: 2, name: 'Durban', fullName: 'KZN • Durban' },
		{ no: 3, name: 'Hillcrest', fullName: 'KZN • Durban • Hillcrest' },
		{
			no: 4,
			name: 'Waterfall',
			fullName: 'KZN • Durban • Hillcrest • Waterfall'
		},
		{ no: 5, name: 'Western Cape', fullName: 'Western Cape' },
		{ no: 6, name: 'Cape Town', fullName: 'Western Cape • Cape Town' },
		{
			no: 7,
			name: 'Office',
			fullName: 'Western Cape • Cape Town • Office'
		}
	]
	*/
};

export default PeopleAssigner;
