module.exports = {
   TASK_ALREADY_STARTED: { code: 1, message: 'The task has already been started.' },
   TASK_ALREADY_SUBMITTED: { code: 2, message: 'The task has already been submitted.' },
   CANT_START_TASK_OUT_OF_WINDOW: { code: 3, message: 'The task cannot be started at this specific time.' },
   CANT_END_TASK_OUT_OF_WINDOW: { code: 4, message: 'The task cannot be ended at this specific time.' },
   CANT_SUBMIT_IF_NOT_STARTED: { code: 5, message: 'Cannot submit if the task has not been started.' },
   INVALID_ACCOUNT: { code: 6, message: 'The account specified is invalid.' },
   CANT_GRADE_TASK_MISSING_SUBMISSION: { code: 6, message: 'Cannot grade a task with a missing submission.' },
   INVALID_CHANNEL_TYPE_NO: { code: 7, message: 'The channel type no specified is invalid.' }
};
