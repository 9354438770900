import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React, { Component } from 'react';
import { DragSource } from 'react-dnd';
import { constants } from '../../../common';
import {
   NumberFormItemIcon,
   OptionsFormItemIcon,
   TextFormItemIcon,
   ToDoFormItemIcon,
   PhotoFormItemIcon,
   DateFormItemIcon,
   SectionFormItemIcon,
   RepeaterFormItemIcon
} from '../../../components/icons';
import Typography from '@material-ui/core/Typography';

const { formItemTypes, repeaterFormItemTypes, datetimeFormItemTypes } = constants;

const externalNodeType = 'formField';

const externalNodeCollect = (connect /* , monitor */) => ({
   connectDragSource: connect.dragSource()
   // Add props via react-dnd APIs to enable more visual
   // customization of your component
   // isDragging: monitor.isDragging(),
   // didDrop: monitor.didDrop(),
});
class externalNodeBaseComponent extends Component {
   render() {
      const { connectDragSource, node, icon, onClick = () => {} } = this.props;

      return connectDragSource(
         <div className='tool'>
            <ListItem button onClick={(ev) => onClick(ev, node)}>
               <ListItemIcon>{icon}</ListItemIcon>
               <ListItemText className={'tool-text'} primary={node.title} />
            </ListItem>
         </div>,
         { dropEffect: 'copy' }
      );
   }
}

let ToolNodeComponent = null;

class FieldToolBox extends Component {
   constructor(props) {
      super(props);

      const dragSpec = {};
      dragSpec.beginDrag = this.beginDrag.bind(this);
      dragSpec.endDrag = this.endDrag.bind(this);

      ToolNodeComponent = DragSource(externalNodeType, dragSpec, externalNodeCollect)(externalNodeBaseComponent);
      this.state = {
         addedNode: null
      };
   }

   beginDrag(componentProps) {
      const { getNextFormItemSync } = this.props;

      var id = getNextFormItemSync();

      let node = { ...componentProps.node };
      node.id = id;
      node.idParent = null;
      node.title = node.data.label;
      node.data.id = id;
      node.data.idParent = null;

      this.setState({ addedNode: { ...node } });

      return { node };
   }

   endDrag(props, monitor, component) {
      const { addedNode } = this.state;
      const { onNewItemAdded } = this.props;

      if (monitor.didDrop()) {
         onNewItemAdded({ item: addedNode.data });
      }
   }

   onAddToolRequested({ node }) {
      const { onAddItemRequested, getNextFormItemSync } = this.props;

      var id = getNextFormItemSync();
      node.data.id = id;
      node.data.idParent = null;

      onAddItemRequested({ item: node.data });
   }

   render() {
      return (
         <div className='FieldToolBox'>
            <span className='heading-toolbox'>Toolbox</span>
            <span className='heading-drag'>(drag or click to add)</span>

            <List className='tools'>
               <ToolNodeComponent
                  icon={<ToDoFormItemIcon style={{ color: 'white' }} />}
                  onClick={(ev, node) => {
                     this.onAddToolRequested({ node });
                  }}
                  node={{
                     title: 'To do',
                     data: { label: '', idRequiredType: 1, allowNotes: true, idItemType: formItemTypes.TODO.id, resources: [] }
                  }}
               />
               <ToolNodeComponent
                  icon={<OptionsFormItemIcon style={{ color: 'white' }} />}
                  onClick={(ev, node) => {
                     this.onAddToolRequested({ node });
                  }}
                  node={{
                     title: 'Options',
                     data: {
                        label: '',
                        idRequiredType: 1,
                        allowNotes: true,
                        idItemType: formItemTypes.OPTIONS.id,
                        options: {
                           idOptionsDisplayType: 1,
                           allowMultiple: false
                        },
                        resources: []
                     }
                  }}
               />
               <ToolNodeComponent
                  icon={<NumberFormItemIcon style={{ color: 'white' }} />}
                  onClick={(ev, node) => {
                     this.onAddToolRequested({ node });
                  }}
                  node={{
                     title: 'Number',
                     data: {
                        label: '',
                        idRequiredType: 1,
                        allowNotes: true,
                        idItemType: formItemTypes.NUMBER.id,
                        resources: [],
                        options: {
                           prefix: null,
                           suffix: null,
                           numericality: {
                              places: 0
                           }
                        }
                     }
                  }}
               />
               <ToolNodeComponent
                  icon={<TextFormItemIcon className='tool-icon' />}
                  onClick={(ev, node) => {
                     this.onAddToolRequested({ node });
                  }}
                  node={{
                     title: 'Text',
                     data: {
                        label: 'Free Text Question',
                        idRequiredType: 1,
                        allowNotes: true,
                        idItemType: formItemTypes.TEXT.id,
                        resources: []
                     }
                  }}
               />

               <ToolNodeComponent
                  icon={<DateFormItemIcon className='tool-icon' />}
                  onClick={(ev, node) => {
                     this.onAddToolRequested({ node });
                  }}
                  node={{
                     title: 'Date & Time',
                     data: {
                        label: '',
                        idRequiredType: 1,
                        allowNotes: true,
                        idItemType: formItemTypes.DATETIME.id,
                        options: { idDateTimeType: datetimeFormItemTypes.DATE.id }
                     }
                  }}
               />

               <ToolNodeComponent
                  icon={<PhotoFormItemIcon style={{ color: 'white' }} />}
                  onClick={(ev, node) => {
                     this.onAddToolRequested({ node });
                  }}
                  node={{
                     title: 'Photo',
                     data: {
                        label: '',
                        idRequiredType: 1,
                        allowNotes: true,
                        idItemType: formItemTypes.PHOTO.id,
                        options: { allowVideo: false, allowLibrary: false },
                        resources: []
                     }
                  }}
               />

               <ToolNodeComponent
                  icon={<SectionFormItemIcon style={{ color: 'white' }} />}
                  onClick={(ev, node) => {
                     this.onAddToolRequested({ node });
                  }}
                  node={{
                     title: 'Section',
                     data: {
                        label: '',
                        idRequiredType: 1,
                        allowNotes: true,
                        idItemType: formItemTypes.SECTION.id,
                        options: { isSubform: false, isSubformLocked: false },
                        resources: []
                     }
                  }}
               />

               <ToolNodeComponent
                  icon={<RepeaterFormItemIcon style={{ color: 'white' }} />}
                  onClick={(ev, node) => {
                     this.onAddToolRequested({ node });
                  }}
                  node={{
                     title: 'Repeater',
                     data: {
                        label: '',
                        idRequiredType: 1,
                        allowNotes: true,
                        idItemType: formItemTypes.REPEATER.id,
                        options: { idRepeaterType: repeaterFormItemTypes.LIST.id },
                        resources: []
                     }
                  }}
               />

               {/*
					<ToolNodeComponent icon={<RatingFormItemIcon />} node={{ title: 'Rating', label: 'Rating Question' }} />
					
					<ToolNodeComponent icon={<BarcodeFormItemIcon />} node={{ title: 'Barcode', label: 'Barcode Question' }} />
					<ToolNodeComponent icon={<SignatureFormItemIcon />} node={{ title: 'Signature', label: 'Signature Question' }} />
					<ToolNodeComponent icon={<StaffFormItemIcon />} node={{ title: 'Staff', label: 'Staff Question' }} />
					<ToolNodeComponent icon={<LocationFormItemIcon />} node={{ title: 'Location', label: 'Location Question' }} />
					
					<ToolNodeComponent icon={<AttachFormItemIcon />} node={{ title: 'Attachment', label: 'Attachment Question' }} />
					<ToolNodeComponent icon={<InformationFormItemIcon />} node={{ title: 'Information', label: 'Information Question' }} />					
					*/}
            </List>
         </div>
      );
   }
}

FieldToolBox.defaultProps = {
   onAddItemRequested: ({ item }) => {},
   onNewItemAdded: ({ item }) => {},
   getNextFormItemSync: () => {}
};

FieldToolBox.propTypes = {};

export default FieldToolBox;
