import React, { Component } from 'react';
class MapWithAMarker extends Component {
   constructor() {
      super();
      this.state = { initialized: false };
      this.map = null;
      this.googleMap = null;
      this.marker = null;
   }

   initializeMap(props) {
      this.setState({ initialized: true });

      console.log('Map --> initializeMap');
      /*
    // loadScript loads an external script, its definition is not included here.
    loadScript('https://maps.google.com/maps/api/js?key=<your_key>', () => {


		});
		*/
      const { lat, lng, zoom, disableDefaultUI, scaleControl, zoomControl, gestureHandling } = props;

      console.log('initializeMap ', props);

      const latlng = new google.maps.LatLng(lat, lng);
      const myOptions = {
         zoom: zoom,
         center: latlng,
         mapTypeControlOptions: {
            mapTypeIds: []
         },
         disableDefaultUI: disableDefaultUI,
         scaleControl: scaleControl,
         zoomControl: zoomControl,
         gestureHandling: gestureHandling
      };
      this.googleMap = new google.maps.Map(this.map, myOptions);
      this.marker = new google.maps.Marker({ position: latlng, map: this.googleMap });
   }

   componentWillUnmount() {
      if (this.marker) {
         this.marker.setMap(null);
         this.marker = null;
         this.googleMap = null;
         this.map = null;
      }
   }

   componentDidMount() {
      if (this.props.isVisible) {
         this.initializeMap(this.props);
      }
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      if (!this.state.initialized && nextProps.isVisible) {
         this.initializeMap(nextProps);
      } else {
         if ((this.props.lat != nextProps.lat || this.props.lng != nextProps.lng) && nextProps.isVisible) {
            if (this.marker) {
               this.marker.setMap(null);
               this.marker = null;
               this.googleMap = null;
            }

            this.initializeMap(nextProps);
         }
      }
   }

   render() {
      return (
         <div
            style={{ height: '100%' }}
            ref={(div) => {
               this.map = div;
            }}
         />
      );
   }
}

MapWithAMarker.defaultProps = {
   isVisible: false,
   lat: 38.34,
   lng: -0.48,
   zoom: 15,
   disableDefaultUI: true,
   scaleControl: false,
   zoomControl: false,
   gestureHandling: 'none'
};

export default MapWithAMarker;
